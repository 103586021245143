import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss'
})
export class CheckboxComponent {
  readonly faCheck = faCheck;
  @Input() isChecked = false;
  @Output() onChanged = new EventEmitter<boolean>();

  change() {
    this.isChecked = !this.isChecked;
    this.onChanged.emit(this.isChecked);
  }
}
